 /** SASS VARIABLES **/
$bjc-blue: #00529B;
$opensans: 'Open Sans', sans-serif;
$background-gray: #F8F7FA;
$black: #1A051D;
$light-blue: #97B8D6;
$gray: #666666;
$purple: #7100ab;
$fontawesome: "Font Awesome 5 Pro";
$yellow: #f8cc46;
$raleway: "raleway", sans-serif;

@font-face {
    font-family: BarnesJewishHospital;
    src: url("/Portals/0/Skins/BJC_2/fonts/BarnesJewishHospital.eot");
    src: url("/Portals/0/Skins/BJC_2/fonts/BarnesJewishHospital.eot?#iefix") format('embedded-opentype'),url("../fonts/BarnesJewishHospital.woff") format('woff'),url("../fonts/BarnesJewishHospital.ttf") format('truetype'),url("../fonts/BarnesJewishHospital.svg#BarnesJewishHospital") format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: BarnesJewishHospital;
    src: url("/Portals/0/Skins/BJC_2/fonts/BarnesJewishHospital.eot");
    src: url("/Portals/0/Skins/BJC_2/fonts/BarnesJewishHospital.eot?#iefix") format('embedded-opentype'),url("../fonts/BarnesJewishHospital.ttf") format('truetype'),url("../fonts/BarnesJewishHospital.woff") format('woff'),url("../fonts/BarnesJewishHospital.svg#BarnesJewishHospital") format('svg');
    font-weight: 400;
    font-style: normal;
}


* {
  font-family: $opensans;
}

body {
  background: $background-gray;
}

body.menu-active {
  overflow-y: hidden;
}

main {
  border: 0px;
  background: $background-gray;
}

:focus {
  outline: none !important;
}

@media (max-width: 991px) {
  .container {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
}

h1,h2,h3,h4,h5 {
  color: $black;
  font-family: $opensans;
  line-height: 1.2;
}

p {
  font-family: $opensans;
  font-size: 14px;
  line-height: 28px;
  color: $black;
}

ul, ol {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 25px;
  color: inherit;
  margin-left: 30px;
}

ul li,
ol li {
  margin-bottom: 5px;
}

#dnnContentPane {
  padding-left: 0px;
  padding-right: 0px;
}

.clear-fix,
.clear {
  clear: both;
}

.no-padding {
  padding: 0px;
}

.relative {
  position: relative;
}

a:hover {
  text-decoration: none;
}

a.full-width-link {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

ul.searchSkinObjectPreview {
  display: none !important;
}

.desktop-header {
  display: none;
}

.mobile-header {
  display: block;
  background: $bjc-blue;
  padding-top: 20px;
  border-bottom: 0px !important;
  overflow: hidden;
  text-align: center;
  padding-bottom: 20px;

  #mobile-menu-trigger {
    margin-top: -10px;
    color: #FFFFFF;
    font-size: 40px;
    transform: rotate(90deg) scaleX(-1);
    display: none;
  }

  .logo {
    padding-top: 5px;
    height: 40px;
    text-align: left;

    img {
      max-width: 240px;
      padding-top: 0px;
    }
  }

  .contactWrap.row2mid.mobile {
    .my-chart-button {
      max-width: 131px;
      display: block;
      margin-right: 0px;
      float: right;
      margin-top: -3px;
    }
  }

  .row2right .input-group .SearchContainer .SearchButton {
      background: url(/Portals/_default/Skins/COVID19/Images/go-button.png);
      color: #ffffff !important;
      display: table-cell;
      height: 41px;
      width: 44px;
      text-align: center;
      line-height: 38px;
      padding: 0 12px;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
      text-transform: uppercase;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0;
      text-decoration: none;
      background-size: cover;
      border: 1px solid #FFFFFF;
  }

  .search-click-to-expand {
    display: none;
  }

  #mobile-search-trigger {
    color: $light-blue;
    font-size: 25px;
    padding-top: 5px;
    display: none;
  }

  .row2right .input-group {
      width: 90%;
      float: none;
      margin: 0 auto;
      margin-top: 13px;
  }

  .mobile-search {
    display: none;
    background: $bjc-blue;
    z-index: -1;
    height: 0px;
    opacity: 0;
    transition: .3s;
    position: relative;

    .container {
      margin-left: 15px;
      margin-right: 15px;
      max-width: 500px;
      margin: 0 auto;
      position: relative;
    }

    .searchInputContainer {
      display: block;
    }

    input {
      background: #FFFFFF !important;
      display: block;
      width: 100%;
      border-radius: 20px;
      font-size: 14px;
      border: 0px;
      height: 35px;
      padding-left: 20px;
    }

    #dnn_ucHeader_dnnSearchMobile_cmdSearch {
      font-size: 20px;
      width: 20px;
      position: absolute;
      right: 40px;
      top: 4px;

      i {
        color: #b7b5b5;
      }
    }

    .SkinObject {
      display: none;
    }

    .SkinObject.show {
      display: block;
    }

    ul.searchSkinObjectPreview,
    .dnnSearchBoxClearText.dnnShow,
    .searchInputContainer a.dnnSearchBoxClearText {
      display: none;
    }
  } /* mobile-search */

  .mobile-search.active {
    margin: 20px 0px 14px 0px;
    height: 100%;
    z-index: 9999;
    height: 100%;
    opacity: 1;
    transition: .3s;
  }
} /* mobile-header */

@media (min-width: 420px) {
  .mobile-header {
    .logo {
      img {
        padding-top: 0px;
      }
    }
  }
}

@media screen and (min-width: 600px) {

  .mobile-header {

    .search-click-to-expand {
      display: inline-block;
      float: right;

      i {
        color: #FFFFFF;
        font-size: 28px;
        padding-top: 6px;
      }
    }

    .contactWrap.row2mid.mobile {
      display: inline-block;
    }

    .row2right {
      display: none;
    }

    .row2right.active {
      display: block;
    }
  }
}

/** mobile-menu **/
.mobile-menu.main {
  background: $bjc-blue;
  width: 100%;
  z-index: -1;
  position: relative;
  padding-top: 20px;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;

  .container {
    max-width: 485px;
  }

  .mobile-menu-image {
    max-width: 230px;
    padding-top: 13px;
  }

  #close-mobile-menu {
    color: $light-blue;
    font-size: 40px;
    z-index: 99999;
    padding-top: 9px;
  }

  ul.nav {
    z-index: 9999;
    background: $bjc-blue;
    width: 100%;
    height: 100vh;
    margin: 0px;
    margin-top: 75px !important;

      li {
        background: $bjc-blue !important;
        color: #FFFFFF !important;
        border: 0px !important;
        margin-bottom: 45px !important;
        display: block !important;
        width: 100%;

          a:hover {
            background: $bjc-blue !important;

            span {
              color: #FFFFFF !important;
            }
          }

          a {
            color: #FFFFFF !important;
            
            span {
              line-height: 20px !important;
              font-family: $opensans;
              padding: 0px !important;
            }
          }
      }

      li.active {
        
          a {
            color: #FFFFFF !important;
            font-weight: bold;
          }
      }
  }

  .slideout-open .slideout-menu {
    display: block;
    z-index: 999;
    margin: 0px;
  }
} /* mobile-menu.main */

.mobile-menu.main.active {
  z-index: 99999;
  opacity: 1;
}

/* Hide mobile header; display desktop header */
@media (min-width: 992px) {

  .mobile-header {
    display: none;
  }

  .desktop-header {
    display: block;
  }

}

.title-bar {
  padding-top: 15px;

  h2 {
    margin: 0px;
    color: #FFFFFF;
    font-size: 20px;
  }
}

@media (min-width: 992px) {

  .title-bar {

    h2 {
      font-size: 25px;
    }
  }
}

/* Desktop header */

.desktop-header {
  border-bottom: 0px;

  .logo-search {
    padding: 25px 0px;
    background: #FFFFFF;

    .logo-container {
      text-align: left;

      img {
        max-width: 350px;
      }
    } /* logo-container */

    .contact-us-container {
    	padding-right: 20px;
    	padding-top: 8px;
    	color: $bjc-blue;
    	text-align: center;
    }

    .search-container {
      text-align: right;
      max-width: 320px;
      position: relative;
      float: right;
      width: 285px;

      input {
        background: #FFFFFF !important;
        display: block;
        width: 300px;
        border-radius: 20px !important;
        font-size: 14px;
        padding: 0px;
        border: 1px solid #CCC;
        border-radius: 15px;
        padding-right: 40px;
        padding-left: 20px;
        font-size: 13px;
        height: 38px;
        color: #7d7d7d;
      }

      .searchInputContainer a.dnnSearchBoxClearText {
        display: none;
      }

      #dnn_ucHeader_dnnSearchDesktop_cmdSearch {
        font-size: 16px;
        width: 20px;
        position: absolute;
        right: 8px;
        top: 8px;

        i {
          color: #b7b5b5;
        }
      }

      .SkinObject {
        display: none;
      }

      .skinObject.show {
        display: block;
      }
    } /* search-container */

  } /* logo-search */

  .desktop-menu {
    background: $bjc-blue;
    text-align: center;
    display: none;

    #dnnMenu {

      ul.nav {
        width: 100%;

        li:first-of-type {
          display: none;
        }

        li {
          text-align: center;
          border-right: 1px solid #CCC;
          margin-bottom: 0px;

          a {
            color: #FFFFFF;
            font-size: 16px;
            line-height: 30px;
            padding: 16px 28px;
            font-family: $opensans !important;
          }

          a:hover,
          a:active,
          a:focus,
          a:visited {
            background: none !important;
          }
        } /* li */

        li:last-of-type {
          border-right: 0px;
        }

        li.active {

          a {
            // color: #FF7F00;
          }
        }

      } /* ul.nav */

    } /* dnnMenu */

  } /* desktop-menu */
} /* desktop-header */

@media (min-width: 1200px) {
  .desktop-header {

  	.logo-search {

  		.contact-us-container {
  			padding-right: 20px;
  			padding-top: 8px;
  			text-align: right;
  		}
  	}

    .desktop-menu {

      #dnnMenu {

        ul.nav {

          li {

            a {
              font-size: 20px;
              padding: 20px 85px;
            }
          } /* li */

        } /* ul.nav */

      } /* dnnMenu */
    } /* desktop menu */
  }/* desktop-header */

} /* min-width 1200px */

/* sub page secondary mobile menu */
html.slideout-open {
  #panel {
    box-shadow: 3px 0px 23px 0px rgba(112,112,112,0.4);
    -webkit-box-shadow: 3px 0px 23px 0px rgba(112,112,112,0.4);
    -moz-box-shadow: 3px 0px 23px 0px rgba(112,112,112,0.4);
  }
} /* html.slideout-open */

.mobile-secondary-menu {
  display: flex !important;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex; 
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

  .project-groups {
  }
}

@media (min-width: 992px) {
  .mobile-secondary-menu {
    opacity: 0;
  }
}

/* home page styles */

.project-groups {

  .group {
    position: relative;
    text-align: center;
    margin-bottom: 15px;

    .icon {
      height: 60px;
      width: 60px;
      margin: 0 auto;
      margin-bottom: 5px;
    }

    .name {
      p {
        margin: 0px;
        font-size: 10px;
        line-height: 15px;
      }
    }

    .category {
      display: none;
    }

  } /* group */

  .group.relative.pg-red .name p {
    color: #F83B3D;
  }

  .group.culture {

    .icon {
      background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/mobile/culture.svg');
      background-size: cover;
    }
  }

  .group.stabilization {

    .icon {
      background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/mobile/stabilization.svg');
      background-size: cover;
    }
  }

  .group.pg-blue .name p {
    color: #00BEFF;
  }

  .group.high-reliability {
    .icon {
      background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/mobile/high-reliability.svg');
      background-size: cover;
    }
  }

  .group.clinical-integration {
    .icon {
      background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/mobile/clinical-integration.svg');
      background-size: cover;
    }
  }

  .group.pg-orange .name p {
    color: #FF7F00;
  }

  .group.team-development {
    .icon {
      background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/mobile/team-development.svg');
      background-size: cover;
    }
  }

  .group.medicaid-expansion {
    .icon {
      background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/mobile/medicaid-expansion.svg');
      background-size: cover;
    }
  }

  .group.pg-purple .name p {
    color: #9A66CE;
  }

  .group.integrated-framework {
    .icon {
      background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/mobile/integrated-framework.svg');
      background-size: cover;
    }
  }

  .group.expanded-capacity {
    .icon {
      background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/mobile/expanded-capacity.svg');
      background-size: cover;
    }
  }

  .group.digital-access {
    .icon {
      background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/mobile/digital-access.svg');
      background-size: cover;
    }
  }

  .group.pg-green .name p {
    color: #7AC100;
  }

  .group.employee-health-plan {
    .icon {
      background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/mobile/employee-health-plan.svg');
      background-size: cover;
    }
  }

  .group.direct-to-employer {
    .icon {
      background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/mobile/direct-to-employer.svg');
      background-size: cover;
    }
  }

  .group.gp-moss .name p {
    color: #008082;
  }

  .group.cost-management {
    .icon {
      background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/mobile/cost-management.svg');
      background-size: cover;
    }
  }
} /* project-groups */

/* project-groups destkop styles */
@media (min-width: 992px) {

  /* home page */

  main.home {

    .black-title {
      text-align: center;

      .heading_wrapper {

        h2 {
          margin-bottom: 35px;
          font-size: 40px;
        }
      }
    } /* black-title */

    .project-groups {

      .group {
        -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);;
        -moz-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
        background: #FFFFFF;
        margin-bottom: 45px;
        padding: 25px 25px;
        max-height: 200px;
        min-height: 200px;

        .full-width-link:hover {
          background: #CCC;
          opacity: .1;
        }

        .icon {
          margin-bottom: 25px;
          height: 80px;
          width: 80px;
        }

        .name {
           p {
            font-size: 16px;
            margin-bottom: 8px;
           }
        }

        .category {
          display: block;
          color: #444;

          p {
            margin-bottom: 0px;
            color: #444;
          }
        }

      } /* group */

      .group.relative.pg-red {
        border-top: 1px solid #F83B3D;
      }
      .group.relative.pg-red .name p {
        color: #F83B3D;
      }

      .group.culture {

        .icon {
          background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/culture.svg');
          background-size: cover;
        }
      }

      .group.stabilization {

        .icon {
          background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/stabilization.svg');
          background-size: cover;
        }
      }

      .group.pg-blue {
        border-top: 1px solid #00BEFF;
      }
      .group.pg-blue .name p {
        color: #00BEFF;
      }

      .group.high-reliability {
        .icon {
          background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/high-reliability.svg');
          background-size: cover;
        }
      }

      .group.clinical-integration {
        .icon {
          background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/clinical-integration.svg');
          background-size: cover;
        }
      }

      .group.pg-orange {
        border-top: 1px solid #FF7F00;
      }
      .group.pg-orange .name p {
        color: #FF7F00;
      }

      .group.team-development {
        .icon {
          background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/health-disparities.svg');
          background-size: cover;
        }
      }

      .group.medicaid-expansion {
        .icon {
          background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/medicaid-expansion.svg');
          background-size: cover;
        }
      }

      .group.pg-purple {
        border-top: 1px solid #9A66CE;
      }
      .group.pg-purple .name p {
        color: #9A66CE;
      }

      .group.integrated-framework {
        .icon {
          background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/integrated-framework.svg');
          background-size: cover;
        }
      }

      .group.expanded-capacity {
        .icon {
          background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/expanded-capacity.svg');
          background-size: cover;
        }
      }

      .group.digital-access {
        .icon {
          background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/digital-access.svg');
          background-size: cover;
        }
      }

      .group.pg-green {
        border-top: 1px solid #7AC100;
      }
      .group.pg-green .name p {
        color: #7AC100;
      }

      .group.employee-health-plan {
        .icon {
          background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/employee-health-plan.svg');
          background-size: cover;
        }
      }

      .group.direct-to-employer {
        .icon {
          background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/direct-to-employer.svg');
          background-size: cover;
        }
      }

      .group.gp-moss {
        border-top: 1px solid #008082;
      }
      .group.gp-moss .name p {
        color: #008082;
      }

      .group.cost-management {
        .icon {
          background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/cost-management.svg');
          background-size: cover;
          margin-bottom: 20px;
        }
      }

    } /* project-groups */

  } /* main.home */

} /* min-width: 992px */

@media (min-width: 1200px) {
  main.home .project-groups .group {
      padding: 30px 25px;
      max-height: 215px;
      min-height: 215px;
  }

  main.home .project-groups .group.cost-management {
  }
}

/* home page content styles */
main.home {

  .message-wrapper {
    background: #FFFFFF;
    padding-top: 50px;
    margin-bottom: 50px;
  }

  .message {
    margin-bottom: 50px;

    h2 {
      margin-bottom: 40px;
    }

    .message-container {
      background: #FFFFFF;
      border-radius: 8px;
      position: relative;

      .image {
        display: none;
      }

      .subtitle {
        margin-top: 5px;
        line-height: 14px;
        font-size: 12px;
        font-style: italic;
        padding-left: 5px;
        padding-right: 5px;
      }

      .header-text {
        margin-bottom: 35px;

        hr {
          display: block;
          border: 3px solid #00529B;
          margin-top: 0px;
          margin-bottom: 20px;
          max-width: 65px;
        }

        h3 {
          font-size: 19px;

          a {
            color: #1A051D;
            font-size: 30px;
          }
        }

        span {
          font-size: 15px;
          color: #C8C8D1;
        }
      } /* header-text */

      .content {
        p.see-all {
          margin-bottom: 0px;
        }
      } /* content */

    } /* message-container */

  } /* message */

  .pm-list {
    h2 {
      margin-bottom: 20px;
    }  
  }

} /* main.home mobile*/

@media (min-width: 992px) {

  main.home {
    margin-top: 25px;

    .two-col-layout {
      position: relative;
      padding: 100px 0px;

      .desktop-colored-backgrounds {

        .gray {
          padding: 50px 0px;
          width: 50%;
          float: left;
          background: #E1DFE6;
          position: absolute;
          left: 0px;
          top: 0px;
          height: 100%;
        }

        .blue {
          width: 50%;
          float: right;
          background: #00529B;
          position: absolute;
          top: 0px;
          left: 50%;
          height: 100%;
        }

      } /* desltop-colored-backgrounds */
    } /* 2-col-layout */

    .message-wrapper {
      background: #FFFFFF;
      padding: 70px 0px;
    }

    .message {
      margin-bottom: 0px;

      h2 {
        display: none;
      }

      .message-container {
        background: transparent;
        border-radius: 0px;
        position: relative;
        padding: 0px;

        .image {
          display: block;
          margin-top: 40px;

          img {
            width: 100%;
            height: auto;
          }
        }

        .header-text {
          margin-top: 40px;

          hr {
            display: block;
            border: 3px solid #00529B;
            margin-top: 0px;
            margin-bottom: 20px;
            max-width: 65px;
          }

          h3 {
            font-size: 29px;
            line-height: 34px;
          }

          span {
            font-size: 16px;
          }
        } /* header-text */

        .content {

        } /* content */

        .header-text,
        .content {
          padding-left: 30px;
        }

      } /* message-container */

    } /* message */

    .pm-container {

      h2 {
        color: #FFFFFF;
        margin-bottom: 40px;
      }
    }
  } /* main.home */
} /* min-width 992px */

@media (min-width: 1200px) {

  main.home {

    .message {
    }

    .pm-container {
      padding-left: 100px;
    }

    .header-text,
    .content {
    }
  } /* main.home */

} /* min width 1200px */

/* project manager EasyDNNNews blocks */
.project-manager {
  border-radius: 8px;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);;
  -moz-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  margin-bottom: 10px;

  .image {
    max-width: 150px;
    float: left;

    img {
      width: 100%;
      height: auto;
    }
  }

  .content {
    padding: 33px 0px;
    width: calc(100% - 175px);
    float: right;
  }

  .pm-name {

    h3 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 5px;
      margin-top: 0px;
    }
  } /* pm-name */

  .pm-exec-title {
    margin-bottom: 15px;
      span {
        font-style: italic;
        font-size: 14px;
      }
  } /* pm-exec-title */

  .pm-title {
    border-left: 2px solid $bjc-blue;
    padding-left: 8px;

    h4 {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 0px;
    }
  }

} /* project-manager */

@media (min-width: 960px) {

  .project-manager {
    border-radius: 0px;

    .content {
      padding: 32px 0px;
    }

    .pm-name {

      h3 {
        color: $bjc-blue;
        font-size: 26px;
        margin-bottom: 7px;
      }
    }

    .pm-exec-title {
      span {
        color: $bjc-blue;
      }
    }

    .pm-title {
      border-left: 2px solid $bjc-blue;

      h4 {
        font-size: 14px;
      }
    }

  } /* project-manager */

  main.home {

    .project-manager {
      border-radius: 0px;
      padding: 25px 20px;
      background: #065EAB;
      margin-bottom: 15px;
      color: #FFFFFF !important;

      .pm-name {
        h3 {
          color: #FFFFFF;
        }
      } /* pm-name */

      .pm-title {
        border-left: 2px solid #FFFFFF;
        h4 {
          color: #FFFFFF;
        }
      }
    } /* project-manager */
  }
}


/* containers */

.module-container {
  margin-bottom: 25px;

  .black-title  h2 {
    color: $black;
    font-size: 17px;
  }
}

.updates-container {

  h2 {
    margin-bottom: 0px;
  }

  .view-all {
    text-align: right !important;
    margin-bottom: 30px;

    a {
      color: $bjc-blue;
      border: 1px solid $bjc-blue;
      border-radius: 12px;
      background: transparent;
      text-align: center;
      padding: 2px 10px;
      text-decoration: none !important;
      font-size: 14px;
    }
  }

} /* updates-container */

@media (min-width: 992px) {

    .black-title {

      h2 {
        margin-bottom: 25px;
        font-size: 37px;
      }
    }

    .updates-container {

      h2 {
        margin-bottom: 0px;
      }

      .view-all {
        text-align: right;

        a {
          color: #FFFFFF;
          background: $bjc-blue;
          text-decoration: none !important;
          padding: 3px 14px;
        }
      }

    } /* updates-container */
} /* min-width 992px */

/* sub page */

main.sub-page {

  .sub-header {
    margin-bottom: 15px;

    .page-title {

      h2 {
        font-size: 17px;
        text-align: left;
        font-weight: 600;
        color: #43425D;
      }
    }

    .secondary-mobile-menu-container {
      text-align: right;
      line-height: 19px;
      display: -webkit-box; 
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex; 
      display: flex;
      display: -webkit-box; 
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex; 
      max-width: 95px;
      float: right;
      margin-top: 15px;

      span,
      i {
        display: inline-block;
      }

      span {
        text-transform: uppercase;
        padding-right: 5px;
        font-size: 10px;
      }

      i {
        font-size: 20px;
      }
      
    } /* secondary-mobile-menu-container */
  } /* sub-header */

  .contentPane {
    border-radius: 8px;
    background: #FFFFFF;
    padding: 20px;
    -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);;
    -moz-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
    margin-bottom: 35px;

    * {
      text-align: left;
    }

    h2 {
      font-size: 22px;
      line-height: 32px;
      color: $bjc-blue;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }

  .newsPane .DnnModule {
    margin-bottom: 60px;
  }

  .project-groups.sidebar {

    .group {
      -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);;
      -moz-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
      background: #FFFFFF;
      margin-bottom: 10px;
      padding-top: 13px;
      padding-bottom: 7px;
      padding-right: 5px;
      min-height: 56px;

      .full-width-link:hover {
        background: #CCC;
        opacity: .1;
      }

      .icon {
        height: 18px;
        width: 18px;
        float: left;
        margin-left: 20px;
        margin-top: 6px;
      }

      .name {
         p {
          font-size: 16px;
          margin-bottom: 8px;
          text-align: left;
          color: #333333 !important;
          margin-left: 20px;
          margin-bottom: 0px;
         }
      }

      .category {
        display: block;
        color: #444;


        p {
          margin-bottom: 0px;
          color: #444;
          text-align: left;
          margin-left: 20px;
          margin-bottom: 0px;
        }
      }

    } /* group */

    .group.relative.pg-red {
      border-left: 4px solid #F83B3D;
    }
    .group.relative.pg-red .name p {
      color: #F83B3D;
    }

    .group.culture {

      .icon {
        background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/culture.svg');
        background-size: cover;
      }
    }

    .group.stabilization {

      .icon {
        background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/stabilization.svg');
        background-size: cover;
      }
    }

    .group.pg-blue {
      border-left: 4px solid #00BEFF;
    }
    .group.pg-blue .name p {
      color: #00BEFF;
    }

    .group.high-reliability {
      .icon {
        background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/high-reliability.svg');
        background-size: cover;
      }
    }

    .group.clinical-integration {
      .icon {
        background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/clinical-integration.svg');
        background-size: cover;
      }
    }

    .group.pg-orange {
      border-left: 4px solid #FF7F00;
    }
    .group.pg-orange .name p {
      color: #FF7F00;
    }

    .group.team-development {
      .icon {
        background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/health-disparities.svg');
        background-size: cover;
      }
    }

    .group.medicaid-expansion {
      .icon {
        background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/medicaid-expansion.svg');
        background-size: cover;
      }
    }

    .group.pg-purple {
      border-left: 4px solid #9A66CE;
    }
    .group.pg-purple .name p {
      color: #9A66CE;
    }

    .group.integrated-framework {
      .icon {
        background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/integrated-framework.svg');
        background-size: cover;
      }
    }

    .group.expanded-capacity {
      .icon {
        background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/expanded-capacity.svg');
        background-size: cover;
      }
    }

    .group.digital-access {
      .icon {
        background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/digital-access.svg');
        background-size: cover;
      }
    }

    .group.pg-green {
      border-left: 4px solid #7AC100;
    }
    .group.pg-green .name p {
      color: #7AC100;
    }

    .group.employee-health-plan {
      .icon {
        background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/employee-health-plan.svg');
        background-size: cover;
      }
    }

    .group.direct-to-employer {
      .icon {
        background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/direct-to-employer.svg');
        background-size: cover;
      }
    }

    .group.gp-moss {
      border-left: 4px solid #008082;
    }
    .group.gp-moss .name p {
      color: #008082;
    }

    .group.cost-management {
      .icon {
        background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/cost-management.svg');
        background-size: cover;
      }
    }

  } /* project-groups sidebar */

} /* main.sub-page */

@media (max-width: 991px) {
  .sidebarContentPane {
    display: none;
  }
}

/* desktop sub-page */
@media (min-width: 992px) {
  main.sub-page {

    .sub-header {

      .page-title {

        h2 {
          font-size: 24px;
          font-weight: normal;
          margin-bottom: 38px;
        }
      }

      .secondary-mobile-menu-container {
        display: none;
      }
    } /* sub-header */

    .contentPane {
      border-radius: 0px;
      padding: 20px 35px;
      margin-bottom: 80px;

      h2 {
        font-size: 40px;
        line-height: 42px;
        margin-bottom: 30px;
      }

      p, ul, ol {
        color: #444;
      }
    } /* contentPane */
    
  } /* main.sub-page */

} /* min-width: 992px */


.news-and-resources {

  main {
  }

  .hero {
    margin-bottom: 75px;
    background-image: url('/Portals/_default/Skins/integriTheme/images/hero.png');
    background-size: cover;
    background-position: center;
    height: 120px;
  }

  .banner-image {
    margin-bottom: 25px;

    img {
      width: 100%; 
      height: auto;
    }
  }

  .black-title {

    h2 {
      text-align: center;
    }
  } /* black-title */

  .article_pager {
    text-align: right;
    margin-top: 60px;

    a.page {
      border: 1px solid #E8E9EC;
      color: $black;
      margin-right: 10px;
      background: #FFFFFF;
      border-radius: 7px;
      padding: 7px 15px;
    }

    a.active.page {
      color: #FFFFFF;
      background: $bjc-blue;
      border: 1px solid $bjc-blue;
    }

    a.page:last-of-type {
      margin-right: 0px;
    }

  } /* article_pager */

} /* news-and-resources */

@media (min-width: 992px) {
  .news-and-resources {
    margin-top: -75px;

    .hero {
      margin-bottom: 75px;
      background-image: url('/Portals/_default/Skins/integriTheme/images/hero.png');
      background-size: cover;
      background-position: center;
      height: 400px;
      margin-top: 75px;
    }

    .banner-image {
      margin-top: 75px;
      margin-bottom: 75px;

      img {
      }
    }
  }
}

/* updates blog posts */

.updates {
  background: #FFFFFF;
  border-radius: 8px;
  text-align: left;
  margin-bottom: 15px;
  -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);;
  -moz-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);

  .content {
    padding: 20px 20px 0px 20px;
  }

  a {
    text-decoration: none !important;
  }

  h2 {
    margin-bottom: 25px;
    text-align: left !important;
  }

  h2 a {
    color: $black !important;
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 25px;
    text-align: left !important;
  }

  .summary {

  }

  .summary p {
    color: #666666;
    font-size: 14px;
    line-height: 17px;

    a {
      color: $bjc-blue !important; 
    }
  }

  .learn-more {
    text-align: right;
    margin-bottom: 15px;
    a {
        color: $bjc-blue !important;
        text-align: right;
      }
  } /* learn-more */

  .category {
    padding: 10px 12px;
    background: $bjc-blue;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;

    a {
      color: #FFFFFF;
    }
  }

} /* updates */

.updates.home {
  .category {
    padding: 10px 12px;
    background: $bjc-blue;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;

    a {
      color: #FFFFFF;
    }
  }
}

@media (min-width: 992px) {

  .updates {
    border-radius: 0px;

    .content {
      padding: 25px 25px 0px 25px;

      h2, h2 a {
        font-size: 24px;
      }

      .summary p {

      }

      .learn-more {
        text-align: left;
      }
    }

    .category {
      text-align: right;
      padding-bottom: 25px;
      padding-right: 25px;
      background: #FFFFFF;

      a {
        border-radius: 18px;
        color: $bjc-blue;
        border: 1px solid $bjc-blue;
        padding: 6px 10px;
      }

      a:hover {
        background: $bjc-blue;
        color: #FFFFFF !important;
      }
    }
  }

  .article.in_list.box.big.edn_articleWrapper.span.updates.home {
      margin-bottom: 30px !important;
  }

  .updates.home {
    width: calc(50% - 30px);
    margin-bottom: 30px;
    border-radius: 0px;

    .admin_actions {

      .admin_action.edit {
        background-image: none;
        background: none;
        color: #FFFFFF !important;
      }
    }

    .category {
      background: transparent;
      margin-bottom: 15px;

      a {
        color: $bjc-blue;
      }
    }
  }

  .updates.home:nth-child(even) {
    margin-right: 30px;
  }

} /* min-width 992px */

/* updates category dropdown */

.category-dropdown-container {
  max-width: 480px;
  margin: 0 auto;
  margin-top: 30px;
}

.category_menu {

  .news-filter {
    background: $bjc-blue;
    font-size: 14px;
    color: #FFFFFF;
    padding: 9px;
    text-align: center;
    margin-bottom: 5px;
  }

  .categories-dropdown {
  background: #FFFFFF;
  position: relative;

    .active-category {
      min-height: 40px;
      background: #FFFFFF;
      min-width: 200px;
      border: 1px solid #BCE0FD;
      position: relative;
      padding-right: 48px;
      padding-left: 10px;
      display: flex;
      display: -ms-flexbox;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;

      span  {
        padding-top: 5px;
        padding-bottom: 5px;
      }

    } /* active-category */

    .active-category::after {
      content: '\f107';
      font-family: "Font Awesome 5 Pro";
      position: absolute;
      right: 10px;
      top: 8px;
      font-size: 18px;
      background: #FFFFFF;
      /* border-radius: 8px; */
      -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
      -moz-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
      padding: 2px 6px;
      line-height: 18px;
      color: $bjc-blue;
    }

    ul.edn_category_menu_wrapper {
      margin-left: 0px;
      display: none;
      background: #FFFFFF;
      -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);;
      -moz-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
      width: 100%;

      li {
        list-style-type: none;
        margin-bottom: 18px;

        a {
          color: $black;
          display: block;
          padding-left: 10px;
          padding-right: 10px;
          line-height: 16px;
        }
      }
    } /* ul */

    ul.edn_category_menu_wrapper.open {
      display: block;
      position: absolute;
      top: 40px;
      padding-top: 18px;
      border: 1px solid #BCE0FD;
      border-top: 0px;
    }

  } /* categories-dropdown */

  .clear-category {
    margin-top: 15px;
    text-align: right;

    a {
      color: #FFFFFF;
      background: $bjc-blue;
      padding: 5px 11px;
      font-size: 14px;
      border-radius: 13px;
    }
  } /* clear-category */

} /* category_menu */

@media (min-width: 992px) {
  .category-dropdown-container {
    max-width: 620px;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 25px;
    display: -ms-flexbox;
  }

  .category_menu {

    .news-filter {
      display: inline-block;
      margin-bottom: 0px;
      height: 50px;
      line-height: 33px;
      text-align: center;
    }

    .categories-dropdown {
      display: inline-block;
      margin-right: 15px;
      min-width: 448px;
      cursor: pointer;
      display: -ms-flexbox;

      .active-category {
        min-height: 50px;
        padding-left: 16px;
        width: 100%;

        span {
          font-size: 17px;
        }
      }

      .active-category::after {
        top: 13px;
      }

      ul.edn_category_menu_wrapper {

        li {
          margin-bottom: 5px;
          a {
            font-size: 17px;
            padding: 15px 25px;
          }

          a:hover {
            background-color: #BCE0FD;
          }
        }
      }

      ul.edn_category_menu_wrapper.open {
        top: 50px;
      }

    }

    .clear-category {
      display: inline-block;

      a {
        font-size: 15px;
      }

    } /* categories-dropdown */
  } /* category_menu */

} /* min-width 992px */


/* single post page styles */
.single-post {


}


.back-to-updates {
  display: none;
}


/* stock bjc footer */
.site--footer {
   background-color: #00529b; 
   padding-bottom: 30px;
}

.site--footer .social-links a {
   color: #ffffff;
   font-size: 36px;
   padding-right: 30px;

}

.site--footer .social-links a:hover i {
  opacity: .9;
}

.site--footer .identity--connect {
   text-align: center;
   padding-top: 4px;
   background-color:  #2D71B1;
   height: 90px;
}

.site--footer .identity--address {
   text-align: center;
}

.site--footer .identity--logo {
   padding-top: 65px;
   padding-bottom: 25px;

   img {
    max-width: 235px;
   }
}

.site--footer .address {
   font-family: 'Open Sans';
   font-size: 15px;
}

.site--footer .address a {
   color: #ffffff;
   text-decoration: none;
   font-size: 16px;
}

.site--footer .address p {
   color: #ffffff !important;
   font-size: 12px !important;
   line-height: 18px;
}

.row13 {
   background: #00529b;
}

.sitemapRow {
   text-align: center;
}

.sitemapRow .container .sitemap-wrapper {
  text-align: center;
}

ul.sitemapMenu {
   border-top: 1px solid #4283b9;
   text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
   margin: 0 auto;
   padding: 0 40px;
   display: table;
}

ul.sitemapMenu li {
  padding: 15px 12px 15px 12px;
  display: table-cell;
  text-align: center;
}

ul.sitemapMenu li a {
  font-size: 12px;
  color: #ffffff;
  text-decoration: none;
}

ul.sitemapMenu li a:hover {
   color: #a5c3de;
}

@media (max-width: 767px) {
  .row13 .sitemapMenu {
      display: block;
      text-align: center;
      padding: 10px 0;
  }
  .row13 .sitemapMenu li {
      display: inline-block;
      padding: 4px 10px;
  }
}


/* slideout.js styles */
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.slideout-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 295px;
  min-height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 0;
  display: none;
}

.slideout-menu-left {
  left: 0;
}

.slideout-menu-right {
  right: 0;
}

.slideout-panel {
  position: relative;
  z-index: 1;
  will-change: transform;
  background-color: $background-gray;
  min-height: 100vh;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}

.slideout-open .slideout-menu {
  display: block;
}

/** ActionForms input styles **/

.DnnModule-ActionForm {

  label {
    margin-bottom: 5px !important;
  }

  .radio,
  .checkbox  {
    margin-left: 0px !important;
  }

  .form-control {
    border-radius: 0px !important;
  }

  input[type=radio],
  input[type=checkbox] {
    margin-top: 3px;
  }

  button {
    background-color: #8bb4ce !important;
    border: 1px solid #8bb4ce !important;
    border-radius: 0px !important;
    padding: 8px 20px !important;
  }

} /* avt-action-form */

/** EasyDNNNews controls styles **/
.admin_actions {
  background-color: $bjc-blue;
  padding: 10px 0px;
}

 .user_dashboard {
  border: 1px solid #ddd;
  border-left: none;
  border-right: none;
  padding: 4px 6px 1px;
  margin: 0 0 20px;
  overflow: hidden;
  background-color: #ebebeb;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
 .user_dashboard:hover {
  background-color: #f2f2f2;
  border-color: #000;
}
 .user_dashboard a {
  display: block;
  float: left;
  font-size: 11px;
  font-family: Arial;
  font-weight: bold;
  padding: 0 0 0 35px;
  height: 25px;
  line-height: 24px;
  margin: 0 10px 0 0;
  color: #5C5C5C !important;
  text-decoration: none !important;
}
 .user_dashboard a.add_article {
  background: url("/DesktopModules/EasyDNNNews/Templates/_default/BJCStrategicPriorities/assets/images/common/user_dashboard/green_plus.png") no-repeat;
  padding-left: 25px;
}
 .user_dashboard a.article_manager {
  background: url("/DesktopModules/EasyDNNNews/Templates/_default/BJCStrategicPriorities/assets/images/common/user_dashboard/papers.png") no-repeat 0 0;
  padding-left: 28px;
}
 .user_dashboard a.event_manager {
  background: url("/DesktopModules/EasyDNNNews/Templates/_default/BJCStrategicPriorities/assets/images/common/user_dashboard/event_manager.png") no-repeat 0 1px;
  padding-left: 35px;
}
 .user_dashboard a.approve_articles {
  background: url("/DesktopModules/EasyDNNNews/Templates/_default/BJCStrategicPriorities/assets/images/common/user_dashboard/approve_articles.png") no-repeat 0 1px;
  padding-left: 22px;
}
 .user_dashboard a.approve_comments {
  background: url("/DesktopModules/EasyDNNNews/Templates/_default/BJCStrategicPriorities/assets/images/common/user_dashboard/approve_comments.png") no-repeat 0 2px;
  padding-left: 22px;
}
 .user_dashboard a.category_manager {
  background: url("/DesktopModules/EasyDNNNews/Templates/_default/BJCStrategicPriorities/assets/images/common/user_dashboard/categories.png") no-repeat 0 2px;
  padding-left: 22px;
}
 .user_dashboard a.dashboard {
  background: url("/DesktopModules/EasyDNNNews/Templates/_default/BJCStrategicPriorities/assets/images/common/user_dashboard/monitor.png") no-repeat 0 3px;
  padding-left: 24px;
}
 .user_dashboard a.settings {
  background: url("/DesktopModules/EasyDNNNews/Templates/_default/BJCStrategicPriorities/assets/images/common/user_dashboard/settings.png") no-repeat 0 1px;
  padding-left: 24px;
}
 .user_dashboard a.author_profile {
  background: url("/DesktopModules/EasyDNNNews/Templates/_default/BJCStrategicPriorities/assets/images/common/user_dashboard/profile.png") no-repeat 0 1px;
  padding-left: 18px;
}
 .user_dashboard a:hover {
  color: #000 !important;
  text-decoration: none !important;
}

 .edn_article.edn_articleDetails .edn_articleTitle {
  margin-bottom: 20px;
}
 .edn_article.edn_articleDetails .edn_articleTitle + .edn_articleSubTitle {
  margin-top: -15px;
}
 .edn_article.edn_articleDetails .edn_articleSubTitle {
  margin-bottom: 20px;
}
 .edn_article.edn_articleDetails .edn_metaDetails {
  margin-bottom: 20px;
}

.edn_adminActions > a.edit,
.admin_action.edit {
  color: #FFFFFF;
  border-color: #e78d00;
  margin-left: 15px;
}
.edn_adminActions > a.edit:before,
.admin_action.edit:before {
  font-family: $fontawesome;
  content: '\f044';
  padding-right: 5px;
}

/** HIDES MODULE TITLE RESULTS FROM SEARCH PAGE **/
.dnnSearchResultItem-Subset {
  display: none !important;
}

/** IE CSS fixes **/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
     .category_menu .categories-dropdown .active-category span {
      padding-top: 6px;
     }
}




/**** COVID 19 STYLES ******/


/* home */
.home {
  .contentPane {
    margin-bottom: 30px;
  }

  #dnn_ResourcesFeedTitle {
    padding-top: 20px;
  }

  #dnn_UpdatesFeed {
    background: #FFFFFF;
    padding: 20px;
  }

  .resource {
    border-top: 3px solid $purple;
    padding: 15px 30px 10px 30px;
    background: #FFFFFF;
    text-align: center;
    margin-bottom: 20px;
    min-height: 175px;
    -webkit-box-shadow: 0px 2px 5px rgba(0, 32, 61, 0.1) !important;
    box-shadow: 0px 2px 5px rgba(0, 32, 61, 0.1) !important;
    position: relative;

    .full-width-link {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 999;
    }

    .icon {
      background: $bjc-blue;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 115px;
      min-height: 95px;
      margin: 0 auto;
      margin-bottom: 10px;

      i {
        color: #FFFFFF;
        font-size: 55px;
      }
    }

    .link-title {
      text-align: center;
      font-weight: 600;
      color: #000000;
      font-size: 15px;
    }

  } /* resource */

  .resource.vs,
  .resource.spreadsheet {

    .icon {

      i:before {
        color: #222529;
      }

      i:after {
        color: #FFFFFF;
        opacity: 1;
      }
    }
  }

  .covid19-updates {
    margin-top: 30px;
	&.featured-story {
		border-top: 10px solid $purple;
		position: relative;
		
		.content {
			background-color: #fff;
			padding: 30px 10px 20px;

			.content {
				padding: 0px;
			}
			h2 {
				border-bottom: 1px solid $purple;
				padding-bottom: 20px;
				a {
					color: #040404;
				}
			}
			.summary {
			@media screen and (min-width: 992px) {
				position: relative;
				padding-left: 85px;
					&:before {
						display: inline-block;
						content: "\f071";
						font-family: $fontawesome;
						font-size: 60px;
						font-weight: 900;
						-webkit-font-smoothing: antialiased;
						color: $yellow;
						position: absolute;
						top: 20px;
						left: 0;
					}
			}
				.date {
					font-weight: 700;
				}
			}
		}
	}
	&.information-resources {
		.read-more {
			display: none;
		}
}
    h3 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;

      a {
        color: #222529;
      }
    }

    .summary p {
      display: inline;
      line-height: 1.75
    }

    .summary-content {
      line-height: 1.8;
    }

    .read-more {
      color: #FFFFFF;
      background: $purple;
      padding: 1px 5px;
      display: inline-block;
      max-width: 52px;
      margin-left: 5px;
    }
  }

  .article_pager {
    margin-top: 40px;
    text-align: center;
    margin-bottom: 10px;
    display: none;

    .page {
      padding: 8px 14px;
      color: #222529;
      margin: 0px 2px;
    }

    .page.active {
      background: $purple;
      color: #FFFFFF;
    }

    .next,
    .last {
      font-size: 0px;
      display: none;
    }

    .next:before {
      content: ">";
      font-size: 15px;
    }

    .last:before {
      content: "<";
      font-size: 15px;
    }
  }

  .video-feed {
    background: #FFFFFF;
    padding-top: 40px;
  }

} /* home */

#dnn_WarningMessage {
  background: #FFFFFF;
  padding: 20px 40px 20px 20px;

  .icon {
    text-align: center;

    i {
      color: #f8cc46;
      font-size: 75px;
      text-align: center;
      margin-bottom: 30px;
    }
  }

  .message {

  }

} /* dnnWarningMessage */

@media screen and (min-width: 992px) {

  .home {

    #dnn_WarningMessage {

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 165px;

        i {
          margin-bottom: 0px;
          font-size: 105px;
        }
      } /* icon */

    } /* dnn warning message */

    .no-padding-left {
      padding-left: 0px;
    }

    .no-padding-right {
      padding-right: 0px;
    }

  } /* home */

} /* min-width 992px */

main.home {
  margin-top: 25px;
}


.inner-page {
  margin-top: 25px;

  #dnn_MainContent {
    padding: 30px !important;
    background: #FFFFFF;
  }

  .contentPane {
    margin-bottom: 30px;
  }

}

.breadcrumbs {
  background: #FFFFFF;
  padding: 10px 20px;

  a {
    color: $purple;
    text-transform: uppercase;
  }
}

.single-post-page .contentPane {
  padding: 30px;
  background: #FFFFFF;
  margin-bottom: 25px;
}

.single-post-page .contentPane.breadcrumbs {
    padding: 10px 20px;
}

.single-post-page {
  .article_pager {
    display: none;
  }
}

.cbp-l-grid-projects-title {
  font-size: 20px;
}

.edn_articleTitle {
  color: #1A051D !important;
}

.video {
  margin-bottom: 30px;
}

.video-title {
  font-size: 20px;
  text-align: center;
  color: #1A051D !important;
  margin-top: 10px;
}

.video-description {
  color: rgb(136, 136, 136);
  font-style: italic;
  text-align: center;
  font-size: 13px;
  line-height: 16px;
}

.publish-date {
  margin-top: 20px;
  span {
    color: #1A051D !important;
    font-size: 14px !important;
  }

  hr {
    border: .5px solid #1A051D !important;
    max-width: 50px;
    margin: 5px 0px;
    margin-bottom: 30px !important;
  }
}

/* Containers */
.fancy {
  h2 {
    margin-top: 10px;
  }
  hr {
    border: .5px solid $purple;
    margin-bottom: 0px;
  }
}

.module-container {
  margin-bottom: 0px;
}


main {
  margin-bottom: 0px;
}


#mainContent-inner {
  padding: 0px;
}

#M495EasyDNNGallery table.EDGlightbox.EDGresponsive {
  max-width: 100% !important;
}

.cbp-l-grid-projects .cbp-caption {
  height: 66% !important;
}

.cbp-wrapper {
  margin-top: 30px;
}

.cbp-l-grid-projects-desc span {
  font-size: 14px;
}

.single-post-page {
  margin-top: 25px;
}


.add_comment {

  h3 {
    color: $bjc-blue;
  }
}

.add_article_box,
.eds_subCollection_news.eds_news_Ozone .article_comments .add_comment > div,
.eds_subCollection_news.eds_news_Ozone .article_comments .add_comment > div table td input[type='text'], .eds_subCollection_news.eds_news_Ozone .article_comments .add_comment > div table td input[type='text']:hover, .eds_subCollection_news.eds_news_Ozone .article_comments .add_comment > div table td input[type='text']:focus, .eds_subCollection_news.eds_news_Ozone .article_comments .add_comment > div table td textarea, .eds_subCollection_news.eds_news_Ozone .article_comments .add_comment > div table td textarea:hover, .eds_subCollection_news.eds_news_Ozone .article_comments .add_comment > div table td textarea:focus,
.eds_subCollection_news.eds_news_Ozone .edn_readMoreButton.edn_readMore, .eds_subCollection_news.eds_news_Ozone .article_comments .add_comment .add_article_box .submit,
.eds_subCollection_news.eds_news_Ozone .article_comments .num_of_comment {
  border: 1px solid $bjc-blue !important;
}

.eds_subCollection_news.eds_news_Ozone .edn_readMoreButton.edn_readMore:hover, .eds_subCollection_news.eds_news_Ozone .article_comments .add_comment .add_article_box .submit:hover {
  background: $bjc-blue;
}

.eds_subCollection_news.eds_news_Ozone .edn_readMoreButton.edn_readMore, .eds_subCollection_news.eds_news_Ozone .article_comments .add_comment .add_article_box .submit {
  color: $bjc-blue;
}

.eds_subCollection_news.eds_news_Ozone .article_comments .num_of_comment a,
.eds_subCollection_news.eds_news_Ozone a {
  color: $bjc-blue !important;
}

.eds_news_Ozone.eds_subCollection_news {
	.edn_article.edn_articleDetails .edn_articleTitle {
		font-size: 38px;
		font-family: $raleway;
		margin-top: 10px;
		margin-bottom: 15px;
		@media (max-width: 767px) {
			font-size: 32px;
		}
	}
	h3, h4, h5, h6 {
		color: $bjc-blue;
	}
	p, li {
		font-size: 16px;
	}
}


.inner-page {

  .covid19-updates {
    margin-top: 50px;

    h3 {
      font-size: 18px;

      a {
        color: #222529;
        font-weight: 600;
      }
    }

    p {
      margin-bottom: 10px;
    }
  }

}



.read-more {
  color: #FFFFFF !important;
  background: $purple;
  padding: 1px 5px;
  display: block;
  max-width: 52px;
  margin-top: 5px;
}

.view-more-posts {
  color: #FFFFFF !important;
  background: $purple;
  padding: 6px 15px;
  text-align: center;
  margin: 0 auto;
  margin-top: 25px;
  display: block;
  max-width: 130px;
}

.video-container {
position: relative;
padding-bottom: 56.25%;
padding-top: 30px; height: 0; overflow: hidden;
}
 
.video-container iframe,
.video-container object,
.video-container embed {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
}


.SubHead img {
  margin-top: -9px;
}

.SubHead {
  font-weight: bold;
}

/* COVID-19 */
.search-container {
	display: none;
}
.desktop-menu {
	.covid19menu {
		.nav {
			display: flex;
			justify-content: center;
			margin-left: 0;
			li {
				margin-bottom: 0;
				a {
					color: #fff;
					border-right: 1px solid #fff;
          padding: 20px 60px;
          font-size: 18px;
					&:hover, &:focus {
						color: $bjc-blue;
						background-color: #eaf0fd;
					}
				}
				&:last-child {
					a {
						border-right: 0;
					}
				}
			}
		}
	}
}
.bjc-response {
	background-color: #fff;
	border-top: 10px solid $yellow;
	padding: 30px 55px 40px;
	margin-bottom: 30px;
	h2 {
		margin-bottom: 20px;
	}
	hr {
		border-color: $yellow;
		@media (min-width: 992px) {
			width: 30%;
		}
	}
	img {
		height: auto;
		margin-bottom: 15px;
		@media (min-width: 992px) {
			float: right;
			max-width: 191px;
			margin-left: 15px;
		}
	}
}
.information-resources {
	h3 {
		position: relative;
		&:before {
			display: inline-block;
			margin-right: 5px;
			content: "\f30f";
			font-family: $fontawesome;
			-webkit-font-smoothing: antialiased;
			color: $purple;
		}
	}
	.read-more {
		display: none;
	}
}

.inner-page.videos {

  #dnn_MainContent {
    margin-bottom: 0px;
  }

  .white-background {
    background: #FFFFFF;
    padding: 0px 30px 30px 30px;
  }
}


.article_pager {
  text-align: center;
  margin-top: 60px;

  a.page {
    border: 1px solid #E8E9EC;
    color: $black;
    margin-right: 10px;
    background: #FFFFFF;
    border-radius: 7px;
    padding: 7px 15px;
  }

  a.active.page {
    color: #FFFFFF;
    background: $bjc-blue;
    border: 1px solid $bjc-blue;
  }

  a.page:last-of-type {
    margin-right: 0px;
  }

} /* article_pager */



.row2mid {
    padding: 4px 0 0 0;

    .contactWrap {
        display: inline-block;
        float: right;
    }

    .contactWrap a {
        display: inline-block;
        color: #828383;
        font-size: 15px;
        padding: 12px 15px;
        text-decoration: none;
    }

    .contactWrap a:first-child {
        padding-left: 0;
        border-right: 1px solid #e6e6e6;
    }

    .contactWrap a:last-child {
        padding-right: 20px;
    }

    .contactWrap .icon-contact {
        font-size: 15px;
        margin-right: 5px;
    }

    [class*=" icon-"], [class^=icon-] {
        font-family: BarnesJewishHospital;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .my-chart-button {
        color: #FFFFFF !important;
        letter-spacing: .8px;
        background: #dc5842;
        transition: .3s;
        border-radius: 2px;
        padding: 11px 18px 11px 55px !important;
        position: relative;
    }

    .my-chart-button .icon {
        height: 25px;
        width: 25px;
        background-repeat: no-repeat;
        background-size: cover;
        display: inline-block;
        background: url(/Portals/0/Skins/BJC_2/images/MyChart-icon.svg);
        left: 20px;
        top: 8px;
        position: absolute;
    }
}

.row2right {
    padding: 4px 0 0 0;
}

.row2right .input-group {
    width: 95%;
    float: right;
  }

  .row2right .input-group .SearchContainer {
    width: 100%;
    height: 38px;
    float: right;
    background-color: #ffffff;
    display: table;
    margin: 0;
    border-collapse: collapse;
    border-spacing: 0;
  }

  .row2right .input-group .SearchContainer .SearchIcon {
    display: none;
  }

  .row2right .input-group .SearchContainer .SearchBorder {
    border: 0;
    background: none;
    display: table-cell;
  }

  .row2right .input-group .SearchContainer .searchInputContainer {
    width: 100%;
    height: 100%;
  }

  .row2right .input-group .SearchContainer .SearchTextBox {
    background: url("/Portals/0/Skins/BJC_2/images/iconMag.png") no-repeat scroll 10px center #ffffff !important;
    border: 1px solid #e6e6e6;
    color: #c0c0c0 !important;
    font-weight: 100 !important;
    width: 100%;
    height: 41px;
    line-height: 38px;
    padding-left: 30px !important;
    padding-right: 20px;
    position: relative;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    margin-top: 1px;
  }

  .row2right .input-group .SearchContainer .SearchButton {
    background: url(/Portals/_default/Skins/COVID19/Images/go-button.png);
    color: #ffffff !important;
    display: table-cell;
    height: 41px;
    width: 44px;
    text-align: center;
    line-height: 38px;
    padding: 0 12px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    text-transform: uppercase;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    text-decoration: none;
    background-size: cover;
  }

  .input-group.searchActive {
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    -webkit-box-shadow: 0px 0px 15px 5px rgba(217, 229, 240, 0.75);
    -moz-box-shadow: 0px 0px 15px 5px rgba(217, 229, 240, 0.75);
    box-shadow: 0px 0px 15px 5px rgba(217, 229, 240, 0.75);
  }

  #SearchChoices {
    display: none;
  }

  /*end of search*/


.article_pager.edn_articleScrolling {
  display: none;
}

/* Chatbot */
#chatbot {
	border: 2px solid $bjc-blue;
	overflow: hidden;
	#chatbotheader {
		justify-content: center;
		width: calc(100% - 10px);
		#chatbotclose {
			margin-left: 15px;
			margin-right: 0;
		}
	}
	#chatbotdiv {
		#chatbotiframe {
			height: calc(100% + 3px);
		}
	}
	@media (max-width: 767px) {
		width: 100% !important;
		height: 100% !important;
		margin: 0 !important;
		#chatbotdiv {
			width: 100%;
		}
	}
}


.ICG_ETH_Title,
.ICG_ETH_Title a {
	font-size: 16px;
	color: #333 !important;
	font-weight: 700;
}

.ICG_ETH_Title a:hover {
	color: #333;
}

.SubHead img {
	display: none;
}

.ModICGModulesExpandableTextHtmlC {
	margin-bottom: 30px;
}

.flex-this {
	text-align: center;

	img {
		margin-bottom: 40px;
	}
}
@media screen and (min-width: 992px) {
	.flex-this {
		display: flex;
		justify-content: space-around;
		align-items: center;

		img {
			margin-bottom: 0px;
		}
	}

}


.module-container {
	position: relative;

	.anchor {
		position: absolute;
		left: 0px;
		margin-top: -75px;
	}
}


@media screen and (max-width: 991px) {

  .click-to-expand {
    width: 70px;
    padding: 10px;
    background: #FFFFFF;
    margin-top: 15px;
    padding-top: 20px;
    padding-bottom: 0px;

    i {
      color: #0a5499;
      font-size: 40px;
      transform: rotate(90deg);
    }
  }

  .click-to-expand.active {
    // background: #f1f1f1;

    i {
      color: #033663;
    }
  }

  .menu {
    display: block;
    width: 100%;
    margin: 0 auto;
    display: none;
    background: #FFFFFF;
    border-top: 4px solid #020202;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    height: 0px;

    a {
      color: #2a2a2a;
        font-family: "Open Sans", sans-serif;
      line-height: 22px;
      font-size: 15px;
      font-weight: 600;
      display: block;
      padding: 15px;
      display: block;
      text-align: left;
      border-bottom: 1px solid #CCC;
    }

    a:last-of-type {
      margin-bottom: 0px;
    }
  }

  .menu.active {
    display: block;
    height: auto;
  }
}


@media screen and (min-width: 992px) {

  .click-to-expand {
    display: none;
  }

	.menu {
		display: block;
		background: transparent;
		max-width: 100%;
		padding: 0px;
		border-bottom: 1px solid #CCC;
		max-width: 100%;
		margin: 0 auto;
    white-space: normal;
    margin-top: 18px;

		a {
			color: #00529B;
		  font-family: "Open Sans", sans-serif;
			line-height: 22px;
			font-size: 16px;
			font-weight: 600;
			border-right: 1px solid #CCC;
			display: inline-block;
			margin-bottom: 0px;
			padding: 20px 23px;
			background: transparent;
			border-left: 0px;
			border-bottom: 0px;
		}

    a:hover {
      color: #004888;
    }

		a:last-of-type {
			border-right: 0px;
		}
	}

}

@media screen and (min-width: 1200px) {

  .menu {
    a {
      font-size: 21px;
    }
  }
}

#open-chatbot {
	cursor: pointer;
}

html {
	scroll-behavior: smooth;
}


.purple-title {

	.heading_wrapper {
		position: absolute;
		top: -23px;
		width: 100%;
		text-align: center;

		.Head {
			margin-bottom: 6px;
		    font: 700 18px/35px "Open Sans", sans-serif;
		    color: #fff;
		    background-color: #7100ab;
		    text-align: center;
		    margin: 0 auto;
		    padding: 0px 20px;
		    text-transform: uppercase;
		}
	}
}

.news ul li {
	font-size: 13px;
}

input[type=checkbox] {
  margin-right: 5px !important;
  margin-left: 0px !important;
}

.bstrap3-material .checkbox label {
  padding-left: 0px;
}


@font-face {
    font-family: opensans;
    src: url("/Portals/_default/Skins/covid19/fonts/opensans-regular-webfont.eot" );
    src: url("/Portals/_default/Skins/covid19/fonts/opensans-regular-webfont.eot?#iefix") format('embedded-opentype'),url("../fonts/opensans-regular-webfont.woff") format("woff"),url("../fonts/opensans-regular-webfont.ttf") format('truetype'),url("../fonts/opensans-regular-webfont.svg#opensans-regular-webfont") format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: opensans-semibold;
    src: url("/Portals/_default/Skins/covid19/fonts/OpenSans-Semibold.eot");
    src: url("/Portals/_default/Skins/covid19/fonts/OpenSans-Semibold.eot?#iefix") format('embedded-opentype'),url("../fonts/OpenSans-Semibold.woff") format('woff'),url("../fonts/OpenSans-Semibold.ttf") format('truetype'),url("../fonts/OpenSans-Semibold.svg#OpenSans-Semibold") format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: opensansbold;
    src: url("/Portals/_default/Skins/covid19/fonts/opensans-bold-webfont.eot");
    src: url("/Portals/_default/Skins/covid19/fonts/opensans-bold-webfont.eot?#iefix") format('embedded-opentype'),url("../fonts/opensans-bold-webfont.woff") format('woff'),url("../fonts/opensans-bold-webfont.ttf") format('truetype'),url("../fonts/opensans-bold-webfont.svg#opensans-bold-webfont") format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: opensanslight;
    src: url("/Portals/_default/Skins/covid19/fonts/opensans-light-webfont.eot");
    src: url("/Portals/_default/Skins/covid19/fonts/opensans-light-webfont.eot?#iefix") format('embedded-opentype'),url("../fonts/opensans-light-webfont.woff") format('woff'),url("../fonts/opensans-light-webfont.ttf") format('truetype'),url("../fonts/opensans-light-webfont.svg#opensans-light-webfont") format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: icomoon;
    src: url("/Portals/_default/Skins/covid19/fonts/icomoon.eot");
    src: url("/Portals/_default/Skins/covid19/fonts/icomoon.eot?#iefix") format('embedded-opentype'),url("../fonts/icomoon.woff") format('woff'),url("../fonts/icomoon.ttf") format('truetype'),url("../fonts/icomoon.svg#icomoon") format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: BarnesJewishHospital;
    src: url("/Portals/_default/Skins/covid19/fonts/BarnesJewishHospital.eot");
    src: url("/Portals/_default/Skins/covid19/fonts/BarnesJewishHospital.eot?#iefix") format('embedded-opentype'),url("../fonts/BarnesJewishHospital.woff") format('woff'),url("../fonts/BarnesJewishHospital.ttf") format('truetype'),url("../fonts/BarnesJewishHospital.svg#BarnesJewishHospital") format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: news_gothic;
    src: url("/Portals/_default/Skins/covid19/fonts/News_Gothic_MT.eot");
    src: url("/Portals/_default/Skins/covid19/fonts/News_Gothic_MT.eot?#iefix") format('embedded-opentype'),url("../fonts/News_Gothic_MT.woff") format('woff'),url("../fonts/News_Gothic_MT.ttf") format('truetype'),url("../fonts/News_Gothic_MT.svg#News_Gothic_MT") format('svg');
    font-weight: 400;
    font-style: normal;
}

@media all and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: opensans;
        src: url("/Portals/_default/Skins/covid19/fonts/opensans-regular-webfont.svg#opensans-regular-webfont") format('svg');
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: opensans;
        src: url("/Portals/_default/Skins/covid19/fonts/opensans-bold-webfont.svg#opensans-regular-webfont") format('svg');
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: opensanslight;
        src: url("/Portals/_default/Skins/covid19/fonts/opensans-light-webfont.svg#opensans-light-webfont") format('svg');
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: icomoon;
        src: url("/Portals/_default/Skins/covid19/fonts/icomoon.svg#icomoon") format('svg');
    }

    @font-face {
        font-family: BarnesJewishHospital;
        src: url("/Portals/_default/Skins/covid19/fonts/BarnesJewishHospital.svg#BarnesJewishHospital") format('svg');
    }

    @font-face {
        font-family: icomoon;
        src: url("/Portals/_default/Skins/covid19/fonts/News_Gothic_MT.svg#icomoon") format('svg');
    }
}

/* GLOBAL BJC FOOTER */

.row1 {
    background: #00529b;
    color: #FFF;
    text-transform: uppercase;
    width: 100%;
    line-height: 40px;
    /*padding: 10px 0;*/
    /*padding-top: 5px;*/
    font-size: 14px;
}

.row1 .container {
  max-width: 980px;
  padding: 0px 10px !important;
}


    .row1 .icon-phone {
        font-size: 12px;
        margin-right: 5px;
    }

    .row1 .row1Right {
        text-align: right;
    }

    .row1 .row1Left, .row1 .row1Right {
        padding: 6px 0 6px 0;
        font-size: 0.857em;
    }

    .row1 .separator {
        padding: 0 10px;
    }

    .row1 .tel {
        color: #FFF !important;
        text-decoration: none;
    }

    .row1 .for-employee {
        background-color: #2d71b1;
        color: #FFF !important;
        display: block;
        padding: 0 40px;
        text-decoration: none;
    }

.row11 {
    background-color: #668fb7;
}

.socialWrap {
    display: table;
    margin: 0 auto 0 auto;
    padding-bottom: 20px;
}

.socialCol {
    border: none;
    margin: 0;
    display: table-cell;
    text-align: center !important;
    vertical-align: top !important;
    padding: 18px 34px 0;
}

    .socialCol:first-child {
        text-align: right;
    }

.socialRow > div {
    display: table;
}

.socialCol {
    border: none;
    margin: 0;
    display: table-cell;
    vertical-align: top !important;
    text-align: center;
}

    .socialCol .gaCss {
        padding-left: 20px;
    }

    .socialCol.fbSoc {
        padding-left: 40px;
        padding-right: 10px;
        padding-top: 20px;
    }

    .socialCol.twitter {
        padding-top: 20px;
    }

    .socialCol .twttrCss {
        padding-left: 20px;
    }
[class*=icon-] {
    display: inline-block;
}

.icon-fb {
    /*background: url(../images/icon_fb.png) no-repeat top left transparent;
    width: 25px;
    height: 25px;*/
    color: #FFFFFF;
    font-size: 38px;
}

.icon-twitter {
   /* background: url(../images/icon_twitter.png) no-repeat top left transparent;
    width: 30px;
    height: 25px;*/
    color: #FFFFFF;
    font-size: 38px;
}

.icon-youtube {
    /*background: url(../images/icon_youtube.png) no-repeat top left transparent;
    width: 35px;
    height: 25px;*/
    color: #FFFFFF;
    font-size: 38px;
}

.icon-pinterest {
    /*background: url(../images/icon_pinterest.png) no-repeat top left transparent;
    width: 25px;
    height: 25px;*/
}

.icon-linkin {
    /*background: url(../images/icon_linkin.png) no-repeat top left transparent;
    width: 29px;
    height: 25px;*/
    color: #FFFFFF;
    font-size: 38px;
}

@media screen and (max-width: 767px) {
  .row11 {
    display: none;
  }
}

[class*=" icon-"], [class^=icon-] {
    font-family: BarnesJewishHospital;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-accordion-open:before {
    content: "\69";
}

.icon-accordion-closed:before {
    content: "\6f";
}



@media (max-width: 767px) {

  /*Footer row 2*/
  .footerRow2 {
      padding: 20px 10px 0 10px;
  }

  .footerWrap .box {
      display: none;
  }

  .footerWrap {
      margin: 0 0 20px 0;
      border: 1px solid #00458e;
  }

  .footerRow2 .footerGroup {
      margin: 0;
      padding: 0;
      margin-right: 0px !important;
  }

  .footerWrap .boxcontent {
      border-top: 1px solid #00458e;
  }

      .footerWrap .boxcontent ul {
          margin: 0 0 0 10px;
      }


  /* Row 12: Copy right*/
  .bjcLogo, .washLogo {
      padding: 0 20px 20px 20px;
  }

  .national {
      margin: 0 20px 20px 20px;
  }

  .bjcLogo img, .washLogo img {
      width: 100%;
      height: auto;
  }

  .newsletterRow {
      padding: 0 10px 20px 10px;
  }

  /*Row 13*/
  .row13 .sitemapMenu {
      display: block;
      text-align: center;
      padding: 10px 0;
  }

      .row13 .sitemapMenu li {
          display: inline-block;
          padding: 4px 10px;
      }


}

@media (min-width: 768px) and (max-width: 991px) {

  /*Footer row 1*/
  .footerRow1 {
  }

      .footerRow1 .col-md-6 {
          padding: 30px 0 30px 0;
          border: none;
      }


  /* Phone Number Row */
  .genInfoNum {
      float: right;
      /*width: 200px;*/
  }

  /*Footer row 2*/
  .footerWrap .box {
      display: none;
  }

  .footerWrap {
      margin: 0 0 20px 0;
      border: 1px solid #00458e;
  }

  .footerRow2 {
      padding-top: 20px;
  }

      .footerRow2 .footerGroup {
          margin: 0;
          padding: 0;
      }

  .footerWrap .boxcontent {
      border-top: 1px solid #00458e;
  }

      .footerWrap .boxcontent ul {
          margin: 0 0 0 10px;
      }

  .row1 .container, .logoRow .container, .row5 .container, .row6 .container, .row7 .container, .row8 .container, .row9 .container, .row10 .container, .row11 .container, .row12 .container, .row13 .container {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
  }

  .footerRow2 .footerGroup {
      margin: 0;
      margin-right: 0px !important;
      padding: 0;
  }
}

/* Footer row 2*/
.footerRow2 {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    display: block;
    background: #00529b;
    padding: 20px 0;
}

.footerRow2 .container {
  max-width: 980px;
}

    .footerRow2 h5 {
        font-weight: normal;
        font-family: "opensanslight", sans-serif;
        text-transform: uppercase;
        margin-top: 10px;
        margin-bottom: 0;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    }

    .footerRow2 h5, .footerBar span {
        color: #c8b69c;
        /*font-size: 1.143em;*/
    }

.footerBar .box {
}

.footerRow2 .footerGroup {
    margin-right: 30px;
}

.footerRow2 ul {
    margin: 0;
    padding: 0;
}

.footerRow2 .header {
    color: #c8b69c;
    line-height: 22px;
    font-size: 14.6px;
}

.footerRow2 li {
    padding: 7px 0 10px 0;
    list-style-type: none;
    font-size: 14px;
    margin-bottom: 0px;
    line-height: 20px;
}

    .footerRow2 li a {
        color: #a5c3de;
        font-size: 13.7px;
        text-decoration: none;
        line-height: 20px;
    }

        .footerRow2 li a:hover {
            color: #FFF;
        }

.footerWrap {
}

    .footerWrap .boxcontent {
    }

.footerBar {
}

    .footerBar .box-title {
    }

        .footerBar .box-title a {
            color: #3d3d3d;
            border: none;
        }

    .footerBar .barspan {
        font-size: 15.2px;
        margin-top: 4px;
        padding: 12px 12px 12px 12px;
    }

    .footerBar .baricon {
        height: 52px;
        padding: 17px 16px 0 16px;
        border-left: 1px solid #00458e;
    }


/* Footer Collapse & Expand Panel Bar */
.box-heading {
    padding: 0;
}

.box-title {
    margin: 0;
    cursor: pointer;
    line-height: 25px;
}

    .box-title a {
        display: block;
        border-bottom: 1px solid #ccc;
    }

.barspan {
    display: inline-block;
    padding: 25px 12px 25px 12px;
    text-transform: uppercase;
    font-size: 1.2em;
    font-family: "opensanslight", sans-serif;
    word-wrap: break-word;
}

.baricon {
    height: 80px;
    float: right;
    padding: 30px 16px 26px 16px;
    border-left: 1px solid #ccc;
}

/*.barspan {
    margin: 6px 0 0 0;
}*/


/* Row 12: copyright*/
.copyRow {
    background: #00529b;
    text-align: center;
}

.footerLogoWrap {
    margin-top: 40px;
    margin-left: 30px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

.copyWrap {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    display: block;
    padding: 10px 0 0 0;
}

    .copyWrap a {
        color: #a5c3de;
        display: inline-block;
    }

.bjcLogo {
    padding: 0 20px 0 0;
    text-align: right;
    border-right: 1px solid #fff;
}

.washLogo {
    padding: 0 0 0 20px;
    text-align: left;
}

.national {
    display: inline-block;
    margin: 10px 0 20px 0;
    color: #fff;
    font-size: 0.714em;
    letter-spacing: 6px;
    text-transform: uppercase;
}

.copyWrap address {
    font-style: normal;
    font-size: 11.998px;
    margin-top: 20px;
    color: #ffffff;
}

    .copyWrap address a {
        color: #ffffff !important;
        text-decoration: none;
    }

.copyWrap small {
    display: inline-block;
    font-size: 11.004px;
    color: #ffffff;
    margin-bottom: 30px;
}

    .copyWrap small a {
        display: inline;
    }

.copyWrap .footer-logo {
    width: 100%;
    text-align: center;
    padding-top: 20px;
}

/* Sitemap Row */
.row13 {
    background: #00529b;
}

.sitemapRow {
    text-align: center;
}

    .sitemapRow .container .sitemap-wrapper {
        text-align: center;
    }

ul.sitemapMenu {
    border-top: 1px solid #4283b9;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    margin: 0 auto;
    padding: 0 40px;
    display: table;
}

    ul.sitemapMenu li {
        padding: 15px 12px 15px 12px;
        display: table-cell;
        text-align: center;
    }


        ul.sitemapMenu li a {
            font-size: 11.998px;
            color: #ffffff;
            text-decoration: none;
        }

            ul.sitemapMenu li a:hover {
                color: #a5c3de;
            }


/* MIKES STYLES */
.screeningbox {
    border: 12px double #fff;
    background-color: #000;
    vertical-align: middle;
    margin-top: 10px;
}
.screeningtext {
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    margin-top: 10px;
    line-height: 25px;
    font-weight: 500;
}
.screeningtextbold {
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
}
.video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}
.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.center2 {
    display: flex;
    justify-content: center;
}
.btn-primary {
    text-align: center;
    width: 200px;
    text-transform: uppercase;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #00529B;
    font-size: 16px;
    letter-spacing: 1px;
 /*   margin-top: 10p*/;
    color: #fff;
    border: none;
    text-shadow: none;
    /* margin-bottom: 10px*/;
    background-image: none;
    border-radius: 4px;
    margin: 0 auto 22px;
}
.btn-primary3 {
    text-align: center;
    width: 200px;
    text-transform: uppercase;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    letter-spacing: 1px;
    background-color: transparent;
    border-width: 2px;
    border-style: solid;
    border-color: #00529b;
    color: #00529b;
    border-radius: 4px;
    margin: 0 auto 22px;
}
  
.btn-primary3a {
    text-align: center;
    width: 280px;
    text-transform: uppercase;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    letter-spacing: 1px;
    background-color: transparent;
    border-width: 2px;
    border-style: solid;
    border-color: #00529b;
    color: #00529b;
    border-radius: 4px;
    margin: 0 auto 22px;
} 
  
  
  
  
  
  
.btn-primary4 {
    text-align: center;
    width: 200px;
    text-transform: uppercase;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 8px;
    padding-left: 8px;
    font-size: 16px;
    letter-spacing: 1px;
    background-color: transparent;
    border-width: 2px;
    border-style: solid;
    border-color: #00529b;
    color: #00529b;
    border-radius: 4px;
    margin: 0 auto 22p;
}
.btn-primary6 {
  text-align: center;
  width: 200px;
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 8px;
  padding-left: 8px;
  font-size: 16px;
  letter-spacing: 1px;
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  border-color: #00529b;
  color: #00529b;
  border-radius: 4px;
  margin: auto;
  background-color: #fff;
}

.callbtn {
  text-align: center;
 background-color: #00529b;
  border: none;
border-radius: 8px; 
  color: #fff;
  padding: 30px 80px;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: 1px;
  font-weight: 700;
  cursor: pointer;
 margin: auto;  
  display: block;
  width: 70%;

}
/* Darker background on mouse-over */
.callbtn:hover {
  background-color: #0d4578;
}
.callbtn i {
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-30%, -50%);
    -webkit-transform: translate(-30%, -50%);
    -moz-transform: translate(-30%, -50%);
    -o-transform: translate(-30%, -50%);
}
.circle-icon {
    background: #23649e;
    width: 68px;
    height: 68px;
    border-radius: 50%;
    text-align: center;
    line-height: 60px;
    vertical-align: middle;
    padding: 10px;
} 


/*.icon-box:before {
    content: "\e925";
}*/

/* ----------------------------------------------------------------------
  Icon Box
-------------------------------------------------------------------------*/
.icon {
    margin-bottom: 16px;
}
div.icon-box:before {
    display: none !important;
}
.icon-box {
    margin-bottom: 50px;
    position: relative;
}
.icon-box .icon {
    height: 64px;
    position: absolute;
    width: 64px;
}
.icon-box .icon i {
    font-size: 48px;
    /*    font-size: 28px;
*/    text-align: center;
    line-height: 66px;
    border-radius: 50%;
}
.icon-box .icon a, .icon-box .icon i {
    width: 100%;
    height: 100%;
    color: #333;
}
.icon-box h3 {
    margin: 0 12px 10px 83px;
    letter-spacing: 0px;
    font-size: 22px;
    line-height: 25px;
    font-weight: 700;
}
.icon-box p {
    margin: 0 0 20px 83px;
    /*    font-size: 14px;
    line-height: 22px;
    font-weight: 400;*/
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 28px;
}
.icon-box-description > p {
    margin-bottom: 20px;
}
.icon-box.effect .icon i {
    z-index: 1;
}
.icon-box.effect .icon i:after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: '';
    box-sizing: content-box;
}
.icon-box .icon i {
    -webkit-transition: background-color 0.2s, color 0.2s;
    transition: background-color 0.2s, color 0.2s;
}
.image-box .image-box-content p i.fa.fa-play {
    padding-left: 3px;
}
.icon-box.medium > h3, .icon-box.medium > h4, .icon-box.medium > h5 {
    margin: 0 12px 10px 84px;
}
.icon-box.medium > p {
    margin: 0 0 20px 84px;
}
.icon-box.text-center, .icon-box.center {
    padding: 0;
    text-align: center;
}
.icon-box.text-center h3, .icon-box.text-center h5, .icon-box.center h3, .icon-box.center h5 {
    margin: 0 12px 10px 0px;
}
.icon-box.text-center p, .icon-box.center p {
    margin: 0 0 20px 0px;
}
.icon-box.text-center .icon, .icon-box.center .icon {
    margin: 0 auto 22px;
    position: relative;
}
.icon-box.text-center p, .icon-box.center p {
    font-size: 14px;
    margin-top: 15px;
}
.icon-box.process {
    border: 1px solid #00529b;
    margin-top: 24px;
    padding: 0 30px 10px;
    border-radius: 0px;
}
.icon-box.process.effect .icon i:hover:after, .icon-box.process.effect:hover .icon i::after {
    opacity: 0;
    border: 0px;
}
.icon-box.process .icon {
    margin-top: 10px;
}
.icon-box.box-type {
    background: #fff;
/*    border: 4px solid #ececec;
*/    border: 4px solid #00529b;
    border-radius: 4px;
    padding: 20px;
    display: block;
}

.icon-box.box-type .btn-primary3a {
  margin-bottom: 0px;
}
.icon-box.box-type .icon {
    margin-top: 20px;
}
/* ----------------------------------------------------------------------
  Helpers
-------------------------------------------------------------------------*/
.m-b-5 {
    margin-bottom: 5px !important;
}
.m-b-10 {
    margin-bottom: 10px !important;
}
.m-b-15 {
    margin-bottom: 15px !important;
}
.m-b-20 {
    margin-bottom: 20px !important;
}
.m-b-25 {
    margin-bottom: 25px !important;
}
.m-b-30 {
    margin-bottom: 30px !important;
}
.m-b-40 {
    margin-bottom: 40px !important;
}
.m-b-45{
    margin-bottom: 45px !important
}
.m-t-0 {
    margin-top: 0px !important;
}
.m-t-5 {
    margin-top: 5px !important;
}
.m-t-10 {
    margin-top: 10px !important;
}
.m-t-15 {
    margin-top: 15px !important;
}
.m-t-20 {
    margin-top: 20px !important;
}
.m-t-25 {
    margin-top: 25px !important;
}
.m-t-30 {
    margin-top: 30px !important;
}
/* ----------------------------------------------------------------------
  Big CTA
-------------------------------------------------------------------------*/
.call-to-action {
    position: relative;
    overflow: hidden;
    padding: 24px 50px 34px;
    margin-bottom: 40px;
}
.call-to-action .container {
    position: relative;
}
.call-to-action h3 {
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 24px;
}
.call-to-action h4 {
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 20px;
}
.call-to-action p {
    margin-top: 0;
    font-size: 14px;
    line-height: 22px;
}
.call-to-action.cta-center {
    text-align: center;
}
.call-to-action.cta-center [class*="col-"] {
    width: 100%;
    max-width: 100%;
    flex: none;
    text-align: center;
    clear: both;
    display: block;
}

@media (max-width: 991px) {
.call-to-action {
    padding: 26px 12px 18px;
}
[class*="col-"] > .call-to-action {
    padding: 26px 26px 18px;
}
}
.background-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.text-light {
    color: #fff !important;
}
  .call-to-action2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  padding: 32px 55px;
  clear: both;
}
  
  
  html .call-to-action2.call-to-action-primary {
  background: #0088CC;
} 
/* ----------------------------------------------------------------------
  Hero
-------------------------------------------------------------------------*/

/* Container holding the image and the text */
.containersafe {
    position: relative;
    text-align: center;
    color: white;
}
/* Tag Centered text */
.herocentered {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "open sans", Helvetica, sans-serif;
    font-weight: 100;
font-size:68px;
    /*font-size: 3vw;*/
    color: #00529b; 
/*  text-shadow: 1px 1px 1px #37638a;
*/  white-space: nowrap; 
}
  
/* Extra small devices (phones, 1024px and down) */
@media only screen and (max-width: 1024px) {
  .herocentered {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "open sans", Helvetica, sans-serif;
    font-weight: 100;
    font-size: 48px;
    color: #00529b; 
}
  } 
  
  
  
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .herocentered {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "open sans", Helvetica, sans-serif;
    font-weight: 100;
    font-size: 24px;
    color: #00529b; 
}
  }
  
/* ----------------------------------------------------------------------
  divider
-------------------------------------------------------------------------*/
.divider {
    height: 2px;
    background-color: #ddd;
    margin: 30px auto;
}

.divider.d3:after {
    content: "";
    display: block;
    width: 25%;
    height: 2px;
    position: relative;
    background-color: #00529b;
    margin: 0 auto;
}  
/* ----------------------------------------------------------------------
  orange sticky
-------------------------------------------------------------------------*/
div.sticky {
   position: fixed;  
   // position: sticky;
   // position: -webkit-sticky;
  top: 26%;
  background-color: #da5947;
  padding: 12px;
  font-size: 20px;
  width:180px;
  color:#fff;
  z-index: 9999;

  .content-container {
    position: relative;

    i {
      position: absolute;
      top: -3px;
      right: -3px;
      cursor: pointer;
    }
  }
}

div.sticky a {
  font-family: "open sans", helvetica;
  text-decoration: none;
  color:#fff;
  
} 

div.sticky.hide {
  display: none;
}

#close {
  float:right;
  display:inline-block;
  padding:2px 5px;
  background:#ccc;
}

/* James Williams Overrides RITM0872101 */

.eds_news_Ozone.eds_subCollection_news,
.eds_news_Ozone.eds_subCollection_news h1,
.eds_news_Ozone.eds_subCollection_news h2,
.eds_news_Ozone.eds_subCollection_news h3,
.eds_news_Ozone.eds_subCollection_news h4,
.eds_news_Ozone.eds_subCollection_news h5,
.eds_news_Ozone.eds_subCollection_news h6,
.eds_news_Ozone.eds_subCollection_news .edn_articleSummary,
.eds_news_Ozone.eds_subCollection_news .edn_mediaContainer.edn_withTime > time > span,
.eds_news_Ozone.eds_subCollection_news .edn_metaDetails,
.eds_news_Ozone.eds_subCollection_news .edn_listEventDetails,
.eds_news_Ozone.eds_subCollection_news .edn_aditionalBox.edn_articleDocuments ul li:before,
.eds_news_Ozone.eds_subCollection_news .EDN_cf_all_fields_container > table tr td .EDN_cf_checkboxList li,
.eds_news_Ozone.eds_subCollection_calendar,
.eds_news_Ozone.eds_subCollection_categoryMenu,
.eds_news_Ozone.eds_subCollection_news .edn_article.edn_articleDetails .edn_articleSubTitle,
.eds_news_Ozone.eds_subCollection_news .in_article_image .image_description,
.eds_news_Ozone.eds_subCollection_news .edn_article.edn_eventsSimple > section > time > span {
    line-height: 1.5em !important;
}

.eds_news_Ozone.eds_subCollection_news .edn_mediaContainer.edn_withTime > time > span:last-child {
    font-size: 1em !important;
}

.eds_news_Ozone.eds_subCollection_news .edn_listEventDetails {
    font-size: 14px !important; 
}

.eds_news_Ozone.eds_subCollection_news .eds_signUpActionBar > div > span > label:before,
.eds_news_Ozone.eds_subCollection_news .edn_article.edn_articleDetails .edn_authorGroupDetails .button.contact,
.eds_news_Ozone.eds_subCollection_news .edn_article.edn_articleDetails .edn_authorGroupDetails .button.full_bio {
    line-height: 2 !important;
}

.eds_news_Ozone.eds_subCollection_news .user_dashboard a,
.eds_news_Ozone.eds_subCollection_news .edn_article.edn_articleDetails .edn_authorGroupDetails .button.social {
    line-height: 2em !important;
}
